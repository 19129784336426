import clsx from 'clsx';
import React, { FC, useEffect, useRef } from 'react';

import SvgIcon from '../svg-icon/svg-icon';
import { Paragraph } from '../typography/paragraph';
import * as accordionStyles from './accordion.module.css';

interface AccordionBarProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
}

const AccordionBar: FC<AccordionBarProps> = ({ title }) => {
    const barRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const accordionBar = barRef.current!;
        const accordionItemParent = accordionBar.closest('li')!;
        const accordionBody = accordionBar.nextElementSibling!;
        const allAccordionItem = document.querySelectorAll(
            `.${accordionStyles.item}`
        );

        const handleAccordion = () => {
            //@ts-ignore

            if (accordionBody.style.maxHeight) {
                accordionItemParent.classList.remove(accordionStyles.active);
                //@ts-ignore
                accordionBody.style.maxHeight = null;
            } else {
                allAccordionItem.forEach((item) => {
                    const allAccordionBody = item.querySelector<HTMLElement>(
                        `.${accordionStyles.body}`
                    );

                    item.classList.remove(accordionStyles.active);
                    //@ts-ignore
                    allAccordionBody.style.maxHeight = null;
                });
                //@ts-ignore
                accordionBody.style.maxHeight =
                    accordionBody.scrollHeight + 'px';
                accordionItemParent.classList.add(accordionStyles.active);
            }
        };

        accordionBar.addEventListener('click', handleAccordion);
    }, []);

    return (
        <div
            className={clsx(
                'grid grid-cols-1fr-auto gap-3 items-center py-2 px-4 cursor-pointer',
                accordionStyles.bar
            )}
            ref={barRef}>
            <Paragraph body={false} className="font-normal">
                {title}
            </Paragraph>

            <SvgIcon
                icon="arrow-right-circle"
                className={clsx(accordionStyles.icon)}
            />
        </div>
    );
};

export default AccordionBar;
