import clsx from 'clsx';
import React, { FC, useEffect, useRef } from 'react';

export interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
    base?: boolean;
    rounded?: 'sm' | 'md' | 'lg' | '' | 'full';
    ratio?: '1:1' | 'auto';
}

const Box: FC<BoxProps> = ({
    base,
    children,
    className,
    ratio,
    rounded,
    ...props
}) => {
    const boxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const box = boxRef.current;

        const handleBoxRatio = () => {
            if (box) {
                box.style.height = `${box.offsetWidth}px`;
            }
        };

        if (ratio === '1:1') {
            setTimeout(() => {
                handleBoxRatio();
            }, 500);
            window.addEventListener('resize', handleBoxRatio);
        }

        return () => {
            if (ratio === '1:1') {
                window.removeEventListener('resize', handleBoxRatio);
            }
        };
    }, []);

    return (
        <div
            className={clsx(
                {
                    'p-4 l-sm:p-5': base,
                    'flex flex-col justify-center items-center': ratio === '1:1'
                },
                rounded !== '' ? `rounded-${rounded}` : 'rounded',
                className
            )}
            ref={boxRef}
            {...props}>
            {children}
        </div>
    );
};

Box.defaultProps = {
    base: true,
    rounded: '',
    ratio: 'auto'
};

export default Box;
