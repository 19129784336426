import clsx from 'clsx';
import React, { forwardRef } from 'react';

import * as accordionStyles from './accordion.module.css';

interface AccordionBodyProps extends React.HTMLAttributes<HTMLDivElement> {}

const AccordionBody = forwardRef<HTMLDivElement, AccordionBodyProps>(
    ({ children }, ref) => {
        return (
            <div ref={ref} className={clsx(accordionStyles.body)}>
                <div
                    className={clsx('px-4 py-3 border-t border-divider-light')}>
                    {children}
                </div>
            </div>
        );
    },
);

export default AccordionBody;
