export const coverageAreasRoute = [
    {
        path: '/coverage-areas/summary',
        url: false,
        title: 'Summary',
    },
    {
        path: '/coverage-areas/metro-manila',
        url: false,
        title: 'Metro Manila & Rizal Province',
    },
    {
        path: '/coverage-areas/luzon',
        url: false,
        title: 'Luzon',
    },
    {
        path: '/coverage-areas/visayas',
        url: false,
        title: 'Visayas',
    },
    {
        path: '/coverage-areas/mindanao',
        url: false,
        title: 'Mindanao',
    },
    {
        path: '/coverage-areas/retail-outlet',
        url: false,
        title: 'Retail Outlet',
    },
];
