import clsx from 'clsx';
import React, { forwardRef } from 'react';

import * as accordionStyles from './accordion.module.css';

interface AccordionItemProps extends React.HTMLAttributes<HTMLLIElement> {}

const AccordionItem = forwardRef<HTMLLIElement, AccordionItemProps>(
    ({ children }, ref) => {
        return (
            <li
                className={clsx(
                    'border-b border-divider-light last:border-b-0',
                    accordionStyles.item
                )}
                ref={ref}>
                {children}
            </li>
        );
    }
);

export default AccordionItem;
