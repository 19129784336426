import clsx from 'clsx';
import React, { forwardRef } from 'react';

interface AccordionProps extends React.HTMLAttributes<HTMLUListElement> {}

const Accordion = forwardRef<HTMLUListElement, AccordionProps>(
    ({ children }, ref) => {
        return (
            <ul
                className={clsx(
                    'rounded border border-divider-light overflow-hidden',
                )}
                ref={ref}>
                {children}
            </ul>
        );
    },
);

export default Accordion;
