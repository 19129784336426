import clsx from 'clsx';
import React, { FC } from 'react';

import { Container } from '../../components/layout/container';
import { Grid } from '../../components/layout/grid';
import { Section } from '../../components/layout/section';
import { Sidebar, SidebarProducts } from '../../components/sidebar';
import { BaseLayout } from '../base-layout';

export interface WithSidebarLayoutProps {
    sidebar?: 'products' | 'coverage';
    sidebarNav: any[];
    sidebarTitle?: 'Our Products' | 'Coverage Areas';
    sidebarSticky?: boolean;
}

const WithSidebarLayout: FC<WithSidebarLayoutProps> = ({
    children,
    sidebar,
    sidebarNav,
    sidebarTitle,
    sidebarSticky
}) => {
    return (
        <BaseLayout>
            <Section
                base={false}
                className={clsx('py-16 t-lg:pb-20 t-lg:pt-16')}>
                <Container>
                    <Grid
                        container
                        defaultGap={false}
                        base={false}
                        className={clsx(
                            't-lg:grid-cols-auto-1fr gap-x-15',
                            'd-sm:gap-x-20'
                        )}>
                        {sidebar === 'products' ? (
                            <SidebarProducts
                                nav={sidebarNav}
                                title={sidebarTitle}
                                sticky={sidebarSticky}
                            />
                        ) : (
                            <Sidebar
                                nav={sidebarNav}
                                title={sidebarTitle}
                                sticky={sidebarSticky}
                            />
                        )}

                        {children}
                    </Grid>
                </Container>
            </Section>
        </BaseLayout>
    );
};

WithSidebarLayout.defaultProps = {
    sidebar: 'products',
    sidebarSticky: false,
    sidebarTitle: 'Our Products'
};

export default WithSidebarLayout;
