import clsx from 'clsx';
import React, { FC, useEffect, useRef } from 'react';

import { Menu } from '../navigation/menu';
import { MenuItem } from '../navigation/menu-item';
import { NavLink } from '../navigation/nav-link';
import SvgIcon from '../svg-icon/svg-icon';
import { Heading } from '../typography/heading';
import './styles.css';

export interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
    nav: {
        path: string;
        title: string;
    }[];
    title: 'Our Products' | 'Coverage Areas' | undefined;
    sticky?: boolean;
}

const Sidebar: FC<SidebarProps> = ({ title, nav, sticky }) => {
    const filterButtonRef = useRef<HTMLButtonElement>(null);
    const sidebarMenuRef = useRef<HTMLUListElement>(null);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const sidebarNavRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const t_lg = window.matchMedia('(min-width: 1024px)');
        const sidebar = sidebarRef.current!;
        const sidebarMenu = sidebarMenuRef.current!;
        const sidebarNav = sidebarNavRef.current!;
        const filterButton = filterButtonRef.current!;
        const header = document.querySelector<HTMLElement>('.header nav')!;
        const body = document.querySelector<HTMLElement>('body')!;

        const handleFilter = () => {
            sidebarMenu.classList.toggle('active');
            body.classList.toggle('overflow-y-hidden');
        };

        if (!t_lg.matches) {
            filterButton.addEventListener('click', handleFilter);
            sidebar.style.marginTop = `${sidebarNav.offsetHeight - 10}px`;
            sidebarMenu.style.top = `${
                header.offsetHeight + sidebarNav.offsetHeight
            }px`;
            sidebarNav.style.top = `${header.offsetHeight}px`;
        }
    }, []);
    return (
        <div
            ref={sidebarRef}
            className={clsx('t-lg:max-w-180px', 'd-sm:max-w-200px', {
                'l-sm:self-start l-sm:sticky l-sm:top-24': sticky
            })}>
            <div className="sidebar__nav mb-2" ref={sidebarNavRef}>
                <Heading tagName="h4" className="mb-0">
                    {title}
                </Heading>
                <button ref={filterButtonRef} className="t-lg:hidden">
                    <SvgIcon icon="filter" size={25} />
                </button>
            </div>

            <Menu
                className={clsx(
                    'sidebar__menu',
                    'fixed z-40 inset-0 p-6 bg-white overflow-y-auto',
                    't-lg:static t-lg:inset-auto t-lg:p-0 t-lg:overflow-y-visible'
                )}
                ref={sidebarMenuRef}>
                {nav.map((item, i) => {
                    return (
                        <MenuItem
                            className="mb-3 last:mb-0"
                            key={`sidebar-menu-item-${i}`}>
                            <NavLink
                                to={item.path}
                                className={clsx('text-semi-sm block')}
                                color="dark-gray">
                                {item.title}
                            </NavLink>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

Sidebar.defaultProps = {
    sticky: false
};

export default Sidebar;
